import React, { Component, useEffect, useState } from "react";
import Footer from "./Footer";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import parse from 'react-html-parser';

function TicketDetail() {
  const { id } = useParams();
  const [tickets, setTicket] = useState([]);
  const { t } = useTranslation();
  // const { description, image } = Ticket_req;
  useEffect(() => {
    var userId = localStorage.getItem("id");
    if (!userId) {
      window.location.href = '/';
    }
  }, []);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log("Error loading language:", err);
      // console.log("Language changed to:", language);
    });
  };
  // const [Ticket_req, setTicketReq] = useState({
  //   description: "",
  //   image: "",
  // });

  // const onInputChange = (e) => {
  //   setTicketReq({ ...Ticket_req, [e.target.name]: e.target.value });
  // };

  // const submit = async (e) => {
  //   e.preventDefault()
  //   try {
  //     let formData = new FormData();

  //     formData.append("user_id", name);
  //     formData.append("email", email);
  //     formData.append("message", message);
  //     formData.append('image', Photo.current.files[0]);

  //     const response = await axios({
  //       method: "post",
  //       url: "https://changermoney.com/exchange/dashboard/Ticket_req/readAll.php" , {
//   headers: {
//     Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,      "Content-Type": "application/json"//   }
// },
//       data: formData,
//       config: { headers: { "Content-Type": "multipart/form-data" } },
//     });

//     if (response.data) { // assuming the response has a data property
//       alert("Thanks for Your Review");
//     } else {
//       alert("Failed");
//     }
//   } catch (error) {
//     console.error("Error during Review:", error);
//       alert("Failed");
//   }

// };
useEffect(() => {
  loadTicket();
}, []);

const loadTicket = async () => {
  const result = await axios.get(
    "https://changermoney.com/exchange/dashboard/tickets__res/readAll.php", {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,      "Content-Type": "application/json"    }
  }
  );
  setTicket(result.data.reverse());
};
return (
  <>
    <section className="main-banner" id="top">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9 col-md-9">
            <div className="singles-contacts-box">
              <div id="second-form" className="invitioncard py-5 text-center">
                <Container className="SHOPALLL" >
                  <Row>

                    {tickets.map((ticket) => (
                      <>
                        {ticket.ticket_id == id ? (
                          <Col lg="12" md="12" sm="12" className="bllogsdetail">

                            <h3>{ticket.subject}</h3>
                            <p>{ticket.name}</p>

                            <p>
                              {parse(ticket.message)}
                            </p>
                          </Col>
                        ) : (
                          ""
                        )}
                      </>
                    ))}



                  </Row>
                </Container>
              </div>

            </div>
          </div>
        </div>

      </div>

    </section>
    <Footer />
  </>
);
}

export default TicketDetail;