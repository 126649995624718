import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { LuFiles } from "react-icons/lu";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import { FaStar } from "react-icons/fa6";
import FormComponent from "./FormComponent";
import { GiCommercialAirplane, GiLouvrePyramid } from "react-icons/gi";
import axios from "axios";
import { Link } from "react-router-dom";
import { PiLockKeyFill } from "react-icons/pi";
import { FaCheckCircle, FaRegUser } from "react-icons/fa";
import { TiWorld } from "react-icons/ti";
import { TbCurrencyBitcoin } from "react-icons/tb";
import emailjs from "@emailjs/browser";
import Table from 'react-bootstrap/Table';
import Swal from "sweetalert2";

function Home() {
  const [methods, setMethod] = useState([]);
  const [methodss, setMethods] = useState([]);
  const [users, setUsers] = useState([]);

  const [exchanges, setExchange] = useState([]);
  const [reviews, setReview] = useState([]);
  const form = useRef();
  const isMobile = window.innerWidth <= 900;

  useEffect(() => {
    loadMethod();
    loadReviews();
    loadRefrence();
    loadExchanges();
    loadMethodT()
    loadUsers()

  }, []);

  const loadMethod = async () => {
    const result = await axios.get(
      "https://changermoney.com/exchange/dashboard/pay_methods/readAll2.php"
    );
    setMethod(result.data);
  };
  const loadExchanges = async () => {
    const result = await axios.get(
      "https://changermoney.com/exchange/dashboard/pay_exchange/readAll.php", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`, 
        "Content-Type": "application/json"
      }
    }
    );
    setExchange(result.data);
  };
  const loadUsers = async () => {
    const result = await axios.get(
      "https://changermoney.com/exchange/dashboard/users/readAll.php", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`, "Content-Type": "application/json"
      }
    }
    );
    setUsers(result.data);
  };
  const loadMethodT = async () => {
    const result = await axios.get(
      "https://changermoney.com/exchange/dashboard/exchanges/readAll.php", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`, "Content-Type": "application/json"
      }
    }
    );
    setMethods(result.data);
  };

  const loadReviews = async () => {
    const result = await axios.get(
      "https://changermoney.com/exchange/dashboard/reviews/readAll.php", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`, "Content-Type": "application/json"
      }
    }
    );
    setReview(result.data);
  };
  const { t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log("Error loading language:", err);
      // console.log("Language changed to:", language);
    });
  };
  const sendEmail = (e) => {

    e.preventDefault();
    emailjs
      .sendForm(
        "service_fra71n6",
        "template_wmc4azd",
        form.current,
        "WDG9NePNUltT6gljD"
      )
      .then(
        (result) => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Message sent successfully',
          });
          // console.log(result.text);
        },
        (error) => {
          // console.log(error.text);
          Swal.fire({
            icon: 'error',
            title: 'error',
            text: 'Something went Wrong',
          });
        }
      );
    e.target.reset();
  };
  const [refrences, setRefrence] = useState([]);

  const loadRefrence = async () => {
    const result = await axios.get(
      "https://changermoney.com/exchange/dashboard/pay_methods/readAll.php", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`, "Content-Type": "application/json"
      }
    }
    );
    setRefrence(result.data);
  };
  const userMethods = methodss.filter(method => method.status == 2 && method.canceled != 1);

  return (
    <>
      <section className="main-banner" id="top">
        <div className="container">
          <div className="row justify-content-between">

            <div className="col-lg-6">
              <div className="caption header-text wow bounceInLeft "
                data-wow-duration="2s">
                <h6>{t("exchangeAgency")}</h6>
                <div className="line-dec"></div>
                <h4>Changer <span>Money</span></h4>
                <p>{t("BeeChangerP")}</p>
                <div className="main-button scroll-to-section dnoned"><a href="#services">{t("Discover")}</a></div>
                <span className="dnoned">or</span>
                <div className="second-button dnoned"><Link to="/Exchange">{t("Check")}</Link></div>
              </div>
            </div>
            <div className="col-lg-5">
              <FormComponent className="wow fadeInUp " data-wow-duration="2s"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="services" id="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-6">
              <div className="row wow bounceInRight " data-wow-duration="2s"
              >
                <div className="col-lg-12">
                  <div className="section-heading"
                  >
                    <h2>{t("provide")} <em> {t("dservices")}</em> {t("and")}
                      <span> {t("Features")}</span> {t("Agency")}</h2>
                    <div className="line-dec"></div>
                    <p>{t("platform")}</p>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="service-item">
                    <div className="icon">
                      <img src="images/services-01.jpg" alt="discover SEO" className="templatemo-feature" />
                    </div>
                    <h4>{t("ProfitableExchange")}</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="service-item">
                    <div className="icon">
                      <img src="images/services-02.jpg" alt="data analysis" className="templatemo-feature" />
                    </div>
                    <h4>{t("High")}</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="service-item">
                    <div className="icon">
                      <img src="images/services-03.jpg" alt="precise data" className="templatemo-feature" />
                    </div>
                    <h4>{t("Economic")}</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="service-item">
                    <div className="icon">
                      <img src="images/services-04.jpg" alt="SEO marketing" className="templatemo-feature" />
                    </div>
                    <h4>{t("Withdraw")}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="feature-area featuress">
        <Container>
          <Row>
            <Col lg="12" md="12">
              <div className="sections-titl wow fadeInUp ">
                <div className="sub-title">
                  <h3>{t("WORKS")}</h3>
                </div>
                <div className="main-title">
                  <h2>{t("Quality")}</h2>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="4" md="6">
              <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s" >
                <div className="feature-thumb">
                  <LuFiles />

                </div>
                <div className="feature-title">
                  <h3>{t("ProfitableExchange")}</h3>
                  <p>
                    {t("Qualitatively")}
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s" >
                <div className="feature-thumb">
                  <PiLockKeyFill />

                </div>
                <div className="feature-title">
                  <h3>{t("High")}</h3>
                  <p>
                    {t("somethingWrong")}
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s">
                <div className="feature-thumb">
                  <FaCheckCircle />

                </div>
                <div className="feature-title">
                  <h3>{t("exchangeguarantee")}</h3>
                  <p>
                    {t("Numerous")}
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s">
                <div className="feature-thumb">
                  <GiLouvrePyramid />

                </div>
                <div className="feature-title">
                  <h3>{t("Economic")}</h3>
                  <p>
                    {t("loyalrates")}
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s">
                <div className="feature-thumb">
                  <TiWorld />

                </div>
                <div className="feature-title">
                  <h3>{t("FastExchange")}</h3>
                  <p>
                    {t("providereliabilty")}
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s">
                <div className="feature-thumb">
                  <TbCurrencyBitcoin />

                </div>
                <div className="feature-title">
                  <h3>{t("exchangedirections")}</h3>
                  <p>
                    {t("populardestinations")}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="projects" id="projects">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-heading  wow fadeInUp" data-wow-duration="2s">
                <h2> <em>{t("Reviews")}</em> {t("and")} <span>{t("Opinions")}</span></h2>
                <div className="line-dec"></div>
                <p>{t("feedback")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Swiper
                spaceBetween={30}

                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                }}
                className="mySwiper reviewssec3"
              >
                {reviews.map(review => (
                  review.status == 1 ?
                    <SwiperSlide>
                      <div className="item">

                        <div className="down-content">
                          <FaRegUser className="userReview" />

                          <h4>{review.name}</h4>
                          <p>
                            {review.message}

                          </p>
                          <div className="testimonial-icon">
                            <ul>
                              {[...Array(5)].map((_, i) => (
                                <li key={i}>
                                  <FaStar
                                    className={i < review.stars ? 'star filled' : 'star'}
                                  />
                                </li>
                              ))}
                            </ul>

                          </div>
                        </div>

                      </div>
                    </SwiperSlide>
                    : null))}

              </Swiper>
            </div>
          </div>
        </div>
      </section>


      <section className="infos infodifferr infoHome" id="infos">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-content">
                {isMobile ? (
                  <div className="mobile-view">
                    {userMethods.length === 0 ? (
                      <tr>
                        <td colSpan="10" style={{ textAlign: "center" }}>{t("NoPrevioustransactions")}</td>
                      </tr>
                    ) : (
                      userMethods.slice(0, 6).map((method, index) => (


                        <div key={index} className="transaction-row">
                          <div className="transaction-item">
                            <strong>{t("Exchanger")}:</strong>
                            <span>
                              {users.find(user => user.id === method.user_id)?.username || ''}
                            </span>
                          </div>
                          {refrences.map(ref => (
                            ref.id === method.first_method_id && (
                              <>
                                <div className="transaction-item img">
                                  <strong>{t("SendFrom")}:</strong>
                                  <span>


                                    <img key={ref.id} src={`https://changermoney.com/exchange/uploads/pay_methods/${ref.image}`} alt="" height="100px" />
                                    <p><span>{ref.name_en}</span></p>
                                  </span>
                                </div>
                                <div className="transaction-item">
                                  <strong>{t("SendAmount")}:</strong>
                                  <span>{method.amount_send}  {ref.currency}</span>
                                </div>
                              </>
                            )
                          ))}
                          {refrences.map(ref => (
                            ref.id === method.second_method_id && (
                              <>
                                <div className="transaction-item img">
                                  <strong>{t("GetTo")}:</strong>
                                  <span>

                                    <img key={ref.id} src={`https://changermoney.com/exchange/uploads/pay_methods/${ref.image}`} alt="" height="100px" />
                                    <p><span>{ref.name_en}</span></p>

                                  </span>
                                </div>
                                <div className="transaction-item">
                                  <strong>{t("GetAmount")}:</strong>
                                  <span>{method.amount_recieve} {ref.currency}</span>
                                </div>
                              </>
                            )
                          ))}
                          <div className="transaction-item">
                            <strong>{t("Date")}:</strong>
                            <span>{method.date}</span>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                ) : (
                  <Table>
                    <thead>
                      <tr className="headerHome">
                        <th><div className="change"><div className="change-thumb"><p><span>{t("Exchanger")}</span></p></div></div></th>
                        <th><div className="change"><div className="change-thumb"><p><span>{t("SendFrom")}</span></p></div></div></th>
                        <th><div className="amrcat-cap-content"><p><span>{t("SendAmount")}</span></p></div></th>
                        <th><div className="change"><div className="change-thumb"><p><span>{t("GetTo")}</span></p></div></div></th>
                        <th><div className="amrcat-cap-content"><p><span>{t("GetAmount")}</span></p></div></th>
                        <th><div className="amrcat-cap-content"><p><span>{t("Date")}</span></p></div></th>
                      </tr>
                    </thead>
                    <tbody>
                      {userMethods.length === 0 ? (
                        <tr>
                          <td colSpan="10" style={{ textAlign: "center" }}>{t("NoPrevioustransactions")}</td>
                        </tr>
                      ) : (
                        userMethods.slice(0, 6).map((method, index) => (
                          <tr key={index}>
                            <td>
                              <div className="change">
                                <div className="change-thumb">
                                  {users.map(user => (
                                    user.id === method.user_id && <p key={user.id}><span>{user.username}</span></p>
                                  ))}
                                </div>
                              </div>
                            </td>
                            {refrences.map(ref => (
                              ref.id === method.first_method_id && (
                                <>
                                  <td>
                                    <div className="change">
                                      <div className="change-thumb">

                                        <img key={ref.id} src={`https://changermoney.com/exchange/uploads/pay_methods/${ref.image}`} alt="" height="100px" />
                                        <p><span>{ref.name_en}</span></p>

                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="amrcat-cap-content">
                                      <p><span>{method.amount_send} {ref.currency} </span></p>
                                    </div>
                                  </td>
                                </>
                              )
                            ))}
                            {refrences.map(ref => (
                              ref.id === method.second_method_id && (
                                <>
                                  <td>
                                    <div className="change">
                                      <div className="change-thumb">

                                        <img key={ref.id} src={`https://changermoney.com/exchange/uploads/pay_methods/${ref.image}`} alt="" height="100px" />
                                        <p><span>{ref.name_en}</span></p>

                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="amrcat-cap-content">
                                      <p><span>{method.amount_recieve} {ref.currency}</span></p>
                                    </div>
                                  </td>
                                </>
                              )
                            ))}
                            <td>
                              <div className="amrcat-cap-content">
                                <p><span>{method.date}</span></p>
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-us section" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-us-content">
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <div className="contact-thumb wow bounceInLeft" data-wow-duration="2s">
                      <img src="images/contact.png" alt="" />
                    </div>
                  </div>
                  <div className="col-lg-7 wow bounceInRight" data-wow-duration="2s">
                    <form id="contact-form" ref={form} onSubmit={sendEmail} method="POST">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="section-heading">
                            <h2><em>{t("ContactUs")}</em> {t("and")} <span> {t("Touch")}</span></h2>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <fieldset>
                            <input type="name" name="name" id="name" placeholder={t("YourName")} required />
                          </fieldset>
                        </div>
                        <div className="col-lg-6">
                          <fieldset>
                            <input type="surname" name="surname" id="surname" placeholder={t("YourSurname")} required />
                          </fieldset>
                        </div>
                        <div className="col-lg-6">
                          <fieldset>
                            <input type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder={t("Yourmail")} required="" />
                          </fieldset>
                        </div>
                        <div className="col-lg-6">
                          <fieldset>
                            <input type="subject" name="subject" id="subject" placeholder={t("Subject")} />
                          </fieldset>
                        </div>
                        <div className="col-lg-12">
                          <fieldset>
                            <textarea name="message" id="message" placeholder={t("YourMessage")}></textarea>
                          </fieldset>
                        </div>
                        <div className="col-lg-12">
                          <fieldset>
                            <button type="submit" id="form-submit" className="orange-button">{t("SendMessageNow")}</button>
                          </fieldset>
                        </div>
                      </div>
                    </form>
                    <div className="more-info">
                      <div className="row">

                        <div className="col-lg-12">
                          <div className="info-item">
                            <i className="fa fa-envelope"></i>
                            <h4><a href="mailto:support@changermoney.com">support@changermoney.com</a></h4>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Container className="py-5">
        <Swiper
          spaceBetween={30}
          // navigation={true}
          autoplay={true}
          modules={[Autoplay]}
          slidesPerView={3}

          breakpoints={{
            640: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 8,
            },
          }}
          className="mySwiper methodsslider"
        >
          {methods.map(method => (
            <SwiperSlide>
              <div className="item">
                <div className="down-content">
                  <img src={`https://changermoney.com/exchange/uploads/pay_methods/${method.image}`} alt="" />
                </div>

              </div>
            </SwiperSlide>
          ))}

        </Swiper>
      </Container>
      <Footer />
    </>
  );
}

export default Home;
