import React, { useEffect, useState } from "react";
import axios from "axios";
import Footer from "../Footer";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { Button, Modal} from "react-bootstrap";
import { FaEye } from "react-icons/fa";
function CanceledTransactions() {
    const [methods, setMethods] = useState([]);
    const [userId, setUserId] = useState(0);
    const [references, setReferences] = useState([]);
    const [payExchanges, setPayExchanges] = useState([]);
    const { t } = useTranslation();

    const changeLanguage = (language) => {
      i18n.changeLanguage(language, (err, t) => {
        if (err) return console.log("Error loading language:", err);
        // console.log("Language changed to:", language);
      });
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                await loadMethods();
                await loadReferences();
                await loadPayExchanges();
                const userId = localStorage.getItem("id");
                if (!userId) {
                    window.location.href = '/';
                    throw new Error("User ID not found in localStorage");

                }
                setUserId(userId);
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle error or redirect to login page
            }
        };
        fetchData();
    }, []);

    const loadMethods = async () => {
        try {
            const result = await axios.get("https://changermoney.com/exchange/dashboard/exchanges/readAll.php?canceled=1")
            setMethods(result.data);
        } catch (error) {
            console.error("Error loading methods:", error);
        }
    };

    const loadReferences = async () => {
        try {
            const result = await axios.get("https://changermoney.com/exchange/dashboard/pay_methods/readAll.php" , {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,      "Content-Type": "application/json"                    }});
            setReferences(result.data);
        } catch (error) {
            console.error("Error loading references:", error);
        }
    };

    const loadPayExchanges = async () => {
        try {
            const result = await axios.get("https://changermoney.com/exchange/dashboard/pay_exchange/readAll.php" , {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,      "Content-Type": "application/json"                    }});
            setPayExchanges(result.data);
        } catch (error) {
            console.error("Error loading pay exchanges:", error);
        }
    };

    const userMethods = methods.filter(method => method.user_id === userId);

    const handleCancel = async (id) => {
        try {
            await axios.get(`https://changermoney.com/exchange/dashboard/exchanges/cancel.php?id=${id}`, {
                   headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
       // Assuming the content type is JSON
        }});
            await loadMethods(); // Reload methods after canceling
        } catch (error) {
            console.error("Error canceling transaction:", error);
            // Handle error
        }
    };

    const getTimeLeft = (exchangeDate, timeCancellation) => {
        if (!exchangeDate || !timeCancellation) return "00:00:00";

        // Convert exchangeDate to timestamp
        const exchangeTimestamp = new Date(exchangeDate).getTime();

        // Parse timeCancellation to get hours, minutes, seconds
        const [hoursStr, minutesStr, secondsStr] = timeCancellation.split(':');
        const hours = parseInt(hoursStr);
        const minutes = parseInt(minutesStr);
        const seconds = parseInt(secondsStr);

        // Calculate expiration time by adding timeCancellation to exchangeDate
        const expiration = exchangeTimestamp + (hours * 60 * 60 * 1000) + (minutes * 60 * 1000) + (seconds * 1000);

        // Calculate time left
        const now = new Date().getTime();
        const timeLeft = expiration - now;

        if (timeLeft <= 0) return "00:00:00";

        const remainingHours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
        const remainingMinutes = Math.floor((timeLeft / (1000 * 60)) % 60);
        const remainingSeconds = Math.floor((timeLeft / 1000) % 60);

        return `${remainingHours}:${remainingMinutes}:${remainingSeconds}`;
    };

    const getPayExchangeTime = (firstMethod, secondMethod) => {
        const payExchange = payExchanges.find(pe => pe.first_method == firstMethod && pe.second_method == secondMethod);
        return payExchange ? payExchange.time_cancelation : "00:00:00"; // Default to "00:00:00" if not found
    };

    const canBeCanceled = (firstMethod, secondMethod) => {
        const payExchange = payExchanges.find(pe => pe.first_method == firstMethod && pe.second_method == secondMethod);
        return payExchange ? payExchange.can_canceled : false;
    };
    const [showModal, setShowModal] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState(null);

    const handleOpenModal = (method) => {
        setSelectedMethod(method);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedMethod(null);
    };
    return (
        <>
            <section className="infos infodifferr" id="infos">
                <div className="container">
                <Table>
                        <thead>
                        <tr className=" headerHome">
                                <th>
                                    <div className="change">
                                        <div className="change-thumb">

                                            <p><span>{t("PaymentProof")}</span></p>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="change">
                                        <div className="change-thumb">

                                            <p><span>{t("SendFrom")}</span></p>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="amrcat-cap-content">
                                        <p><span> {t("SendAmount")}</span></p>
                                    </div>
                                </th>

                                <th>
                                    <div className="change">
                                        <div className="change-thumb">

                                            <p><span>{t("GetTo")}</span></p>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="amrcat-cap-content">
                                        <p><span> {t("GetAmount")}</span></p>
                                    </div>
                                </th>
                                <th>
                                    <div className="amrcat-cap-content">
                                        <p><span>{t("TransactionID")}</span></p>
                                    </div>
                                </th>
                                <th>
                                        <div className="amrcat-cap-content">
                                            <p><span>{t("Response")}</span></p>
                                        </div>
                                    </th>
                                <th>
                                    <div className="amrcat-cap-content">
                                        <p><span> {t("Date")}</span></p>
                                    </div>
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {userMethods.length === 0 ? (
                              <tr>
                                        <td colSpan="10" style={{ textAlign: "center" }}>{t("NoPrevioustransactions")}</td>
                                    </tr>
                            ) : (
                                userMethods.map((method) => (
                                    <tr>
                                        <>
                                        <td>
                                                <div className="amrcat-cap-content">
                                                <img src={`https://changermoney.com/exchange/uploads/exchanges/${method.image}`} alt="" height="100px" />
                                                </div>
                                            </td>

                                            {
                                                references.map(refrence => (
                                                    refrence.id == method.first_method_id ?
                                                        <>
                                                            <td>
                                                                <div className="change">
                                                                    <div className="change-thumb">
                                                                        <img src={`https://changermoney.com/exchange/uploads/pay_methods/${refrence.image}`} alt="" height="100px" />

                                                                        <p><span>{refrence.name_en}</span></p>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                      

                                            <td>
                                                <div className="amrcat-cap-content">
                                                    <p><span>{method.amount_send} {refrence.currency}</span></p>
                                                </div>
                                            </td>
                                            </>
                                            : null))}
                                            {
                                                references.map(refrence => (
                                                    refrence.id == method.second_method_id ?
                                                        <>
                                                            <td>
                                                                <div className="change">
                                                                    <div className="change-thumb">
                                                                        <img src={`https://changermoney.com/exchange/uploads/pay_methods/${refrence.image}`} alt="" height="100px" />

                                                                        <p><span>{refrence.name_en}</span></p>
                                                                    </div>
                                                                </div>
                                                            </td>


                                                 
                                            <td>
                                                <div className="amrcat-cap-content">
                                                    <p><span>{method.amount_recieve} {refrence.currency}</span></p>
                                                </div>
                                            </td>
                                            </>
                                            : null))}
                                            <td>
                                                <div className="amrcat-cap-content">
                                                    <p><span>{method.transaction_id}</span></p>
                                                </div>
                                            </td>
                                            <td>
                                                    <div className="amrcat-cap-content">

                                                        <p><span>{method.response?.length > 0 ? <Button variant="primary" onClick={() => handleOpenModal(method)}>
                                                            <FaEye />
                                                        </Button> :'No Response'}</span></p>
                                                    </div>
                                                </td>
                                            <td>
                                                <div className="amrcat-cap-content">
                                                    <p><span>{method.date}</span></p>
                                                </div>
                                            </td>


                                        </>

                                    </tr>
                                )))}
                        </tbody>

                    </Table>
                </div>
            </section>
            <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Payment Method Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedMethod ? (
                            <>
                                <p> {selectedMethod.response}</p>

                            </>
                        ) : (
                            <p>No method selected.</p>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            <Footer />
        </>
    );
}

export default CanceledTransactions;
