import React from 'react'
import { useTranslation } from 'react-i18next';
import './i18n';
import parse from 'react-html-parser';
import  Footer  from './Footer';

function Privacy() {
    const { t, i18n } = useTranslation();

    const handleLanguageChange = (lang) => {
      i18n.changeLanguage(lang);
    };
  
  return (
    <>
    <div className="terms_content">
    <h2>{t('policy_title')}</h2>
    {t('policy', { returnObjects: true }).map((section, index) => (
      <div key={index} className="section">
        <h3>{section.title}</h3>
        <p>{parse(section.text)}</p>
      </div>
    ))}
  </div>
  <Footer />
</>
  )
}

export default Privacy
