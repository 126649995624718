import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import { Link } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { CiBookmarkCheck } from "react-icons/ci";
import { FaRegHandshake } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { MdSupportAgent } from "react-icons/md";
import { GiCommercialAirplane } from "react-icons/gi";
import { IoChatbubblesSharp } from "react-icons/io5";
import { MdOutlinePolicy } from "react-icons/md";
import { MdOutlineSecurity } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay} from "swiper/modules";

function AboutUs() {
  const { t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log("Error loading language:", err);
      // console.log("Language changed to:", language);
    });
  };
  return (
    <>
      <section className="breadcumb-area d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breacumb-content wow fadeInUp " data-wow-duration="2s">
                <div className="breadcumb-title">
                  <h1>{t("About")}</h1>
                </div>
                <div className="breadcumb-content-text">
                  <Link to="/">
                    <span>{t("Home")}</span> {t("About")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services my-5" id="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-6">
              <div className="row wow bounceInRight " data-wow-duration="2s"
                  >
                <div className="col-lg-12">
                  <div className="section-heading"
                  >
                    <h2>{t("provide")} <em> {t("dservices")}</em> {t("and")}
                      <span> {t("Features")}</span> {t("Agency")}</h2>
                    <div className="line-dec"></div>
                    <p>{t("platform")}</p>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="service-item">
                    <div className="icon">
                      <img src="images/services-01.jpg" alt="discover SEO" className="templatemo-feature" />
                    </div>
                    <h4>{t("ProfitableExchange")}</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="service-item">
                    <div className="icon">
                      <img src="images/services-02.jpg" alt="data analysis" className="templatemo-feature" />
                    </div>
                    <h4>{t("High")}</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="service-item">
                    <div className="icon">
                      <img src="images/services-03.jpg" alt="precise data" className="templatemo-feature" />
                    </div>
                    <h4>{t("Economic")}</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="service-item">
                    <div className="icon">
                      <img src="images/services-04.jpg" alt="SEO marketing" className="templatemo-feature" />
                    </div>
                    <h4>{t("Withdraw")}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="aboutsec2">
        <Container>
          <Row className="justify-content-between">
            <Col lg="4" md="4" sm="12" className="divmainsec2">
              <div className="wow fadeInUp " data-wow-duration="2s">
                <div className="divsec2222">
                <FaRegEye />

                </div>
                <h3>{t("OurVision")}</h3>
                <p>- {t("GoodService")}</p>
                <p>- {t("ForCommunity")}</p>
                <p>- {t("LongDevelopment")}</p>
                <p>- {t("HelpPeople")}</p>
              </div>
            </Col>
            <Col lg="4" md="4" sm="12" className="divmainsec2">
              <div  className="wow fadeInUp " data-wow-duration="2s">
                <div className="divsec2222">
                <CiBookmarkCheck />

                </div>
                <h3>{t("OurMision")}</h3>
                <p>- {t("ChangeHabits")}</p>
                <p>- {t("BestQuality")}</p>
                <p>- {t("ThinkBigger")}</p>
                <p>- {t("Stability")}</p>
                <p>- {t("Safer")}</p>
              </div>
            </Col>
            <Col lg="4" md="4" sm="12" className="divmainsec2">
              <div  className="wow fadeInUp " data-wow-duration="2s">
                <div className="divsec2222">
                <FaRegHandshake />
                </div>
                <h3>{t("Promise")}</h3>
                <p>- {t("SustainableRelationship")}</p>
                <p>- {t("RenewCommitment")}</p>
                <p>- {t("ProvideSolution")}</p>
                <p>- {t("ProfitableRelationship")}</p>
                <p>- {t("AdaptNeeds")}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="aboutsec3">


        <Container>
          <Row>
          <Swiper          
           spaceBetween={30}
          // navigation={true}
          autoplay={true}
          modules={[Autoplay]}
          slidesPerView={3}

          breakpoints={{
            640: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 6,
            },
          }}
          className="mySwiper"
          
          >
          <SwiperSlide>
            <Col className="wow fadeInUp" data-wow-duration="2s">
              <GrTransaction />
              <p>{t("Transaction")}</p>
            </Col>
          </SwiperSlide>
          <SwiperSlide>
            <Col className="wow fadeInUp" data-wow-duration="2s">
              <MdSupportAgent />
              <p>{t("Support")}</p>
            </Col>
          </SwiperSlide>
          <SwiperSlide>
            <Col className="wow fadeInUp" data-wow-duration="2s">
              <IoChatbubblesSharp />
              <p>{t("Easy")}</p>
            </Col>
          </SwiperSlide>
          <SwiperSlide>
            <Col className="wow fadeInUp" data-wow-duration="2s">
              <GiCommercialAirplane />
              <p>{t("Fast")}</p>
            </Col>
          </SwiperSlide>
          <SwiperSlide>
            <Col className="wow fadeInUp" data-wow-duration="2s">
              <MdOutlinePolicy />
              <p>{t("Policy1")}</p>
            </Col>
          </SwiperSlide>
          <SwiperSlide>
            <Col className="wow fadeInUp" data-wow-duration="2s">
              <MdOutlineSecurity />
              <p>{t("Security")}</p>
            </Col>
          </SwiperSlide>
        </Swiper>

          </Row>
        </Container>
      </section>
     
      <Footer />
    </>
  );
}

export default AboutUs;
