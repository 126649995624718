// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      dir: "ltr",
      Home: "Home",
      About: "About",
      contact: "Contact US",
      FAQ: "FAQs",
      profile: "Profile",
      logout: "Log Out",
      Services: "Services",
      exchangeAgency: "EXCHANGE CURRENCY AGENCY",
      Discover: "Discover More",
      Check: "Check our Exchanges",
      Enter: "Enter Amount",
      BeeChanger: "Changer Money",
      BeeChangerP: "Welcome to our cutting-edge money transfer platform , where convenience meets security. With seamless transactions between all major banks, we pride ourselves on being the simplest, safest, and most secure option available. Plus, we offer competitive rates to ensure you get the best value for your money.",
      Exchange: "Exchange",
      from: "From",
      to: "To",
      provide: "We Provide ",
      dservices: "Different Services",
      Features: "Features",
      Agency: "For Our Agency",
      platform: "Our platform ensures ease and security, providing you with the best deals and competitive rates.",
      ProfitableExchange: "Profitable Exchange",
      High: "High Level Reliabilty",
      Economic: "Economic Exchange",
      Withdraw: "Withdraw referrals",
      WORKS: "How Changer Money works",
      Quality: "We Take Care Quality",
      Qualitatively: "Qualitatively  change the Currency online",
      somethingWrong: "if something went wrong , we will always refund your money",
      exchangeguarantee: "100% exchange guarantee",
      Numerous: " Numerous positive reviews confirm the high quality of our work",
      loyalrates: " we always have loyal rates , we are always open to cooperation , please contact",
      providereliabilty: "we provide not only reliabilty and the best rate , but also for quick exchange",
      populardestinations: "we make exchanges in the most popular destinations , you can always exchange your funds",
      exchangedirections: "a lot of exchange directions",
      FastExchange: "Fast Exchange",
      Reviews: "Reviews",
      and: "&",
      Opinions: "Opinions",
      feedback: "feedback reinforces our commitment to being the best in the industry, ensuring every exchange is smooth, secure, and beneficial.",
      ContactUs: "Contact Us ",
      Touch: "Get In Touch",
      YourName: " Name",
      YourSurname: " Last Name",
      YourUsername: " UserName",
      Yourmail: " E-mail",
      YourMessage: " Message",
      Subject: "Subject",
      SendMessageNow: "Send Message Now",
      OurVision: "Our Vision",
      GoodService: "Good Service",
      ForCommunity: "For Community",
      LongDevelopment: "Long Term Development",
      SavePlanet: "Save Our Planet",
      HelpPeople: "Help People",
      OurMision: "Our Mision",
      ChangeHabits: "Change The Habits",
      BestQuality: "Best Quality",
      ThinkBigger: "Think Big Do Bigger",
      Stability: "Stability & competence",
      Safer: "Safer & Better Life",
      Promise: "Our Promise",
      SustainableRelationship: "Sustainable Relationship",
      RenewCommitment: "Renew Commitment",
      ProvideSolution: "Provide The Best Solution",
      ProfitableRelationship: "Profitable Relationship",
      AdaptNeeds: "Adapt With People's Needs",
      Transaction: "Transaction",
      Support: "Support",
      Easy: "Easy",
      Fast: "Fast",
      Policy: "Policy",
      Security: "Security",
      OurContacts: "Our Contacts",
      AddressInfo: "Address Info",
      Cairo: " Cairo , Egypt",
      PhoneCalls: "Phone Calls",
      EMailAddress: "E-Mail Address",
      MostAsked: "Most Frequently Asked",
      Questions: "Questions",
      Here: "Here",
      StepsSuccess: "Our 4 Steps To Success",
      HappyClients: "Happy Clients",
      quickanswers: "We offer quick answers to common questions about our services",
      SendReview: "Send Review",
      AddReview: "Add Your Review",
      Transactions: "Every transaction is executed with precision and care, ensuring a seamless and secure process from start to finish. Trust us to handle your exchanges efficiently and reliably."
      , SecurePayment: "Our platform guarantees secure payment methods, providing peace of mind for all your transactions. We prioritize your safety and privacy, ensuring that your financial information is always protected."
      , UniversalAccess: "Experience universal access with our platform, enabling you to perform transactions from anywhere in the world. Our services are designed to be convenient and accessible, no matter where you are."
      , Policy: "Our policies are transparent and customer-focused, designed to ensure a fair and secure experience for all users. We are committed to upholding the highest standards of integrity and reliability."
      , Transaction1: "Transaction",
      SecurePayment1: "Secure Payment",
      UniversalAccess1: "Universal Access",
      Policy1: "Policy",
      terms: "terms",
      Follow: "Follow",
      Password: "Password",
      Donthave: "Dont have an Account ?",
      CreateNow: "Create Now",
      YourPhone: " Phone",
      Register: "Register",
      HaveAccount: " Have an Account ?",
      LoginHere: "Sign in Here",
      SignIn: "SignIn",
      Send: "Send",
      ReceiveAmount: "Receive Amount",
      Receive: "Receive",
      Next: "Next",
      Wallet: "Wallet",
      ReceiveWallet: "Receive Wallet or id or Email or User",
      Proofpayment: "upload a photo ",
      ConfirmPassword: "Confirm Password",
      TransactionsNav: "Transactions",
      Blogs: "Blogs",
      Bonus: "Bonus",
      Dashbard: "Dashbard",
      Withdraw: "Withdraw",
      NewWithdraw: "New Withdraw",
      AllWithdraw: "History of Withdraw",
      Tickets: "Tickets",
      NewTicket: " New Ticket",
      PendingTransactions: "Pending Transactions",
      ProcessingTransactions: "Processing Transactions",
      CompletedTransactions: "Completed Transactions",
      CanceledTransactions: "Canceled Transactions",
      RefundedTransactions: "Refunded Transactions",
      AllTransactions: "All Transactions",
      InviteFriends: "Invite Friends",
      Afflication: "Afflication",
      Dashboard: "Dashboard",
      Exchanger: "Exchanger",
      SendFrom: "Send From",
      SendAmount: "Send Amount",
      GetTo: "Get To",
      GetAmount: "Get Amount",
      Date: "Date",
      NoPrevioustransactions: "No Previous Transactions",
      RECENT: "RECENT",
      ReadMore: "Read More",
      Search: "Search",
      Withraw: "Withraw",
      fromYour: "from Your",
      Balance: "Balance",
      CurrentBalance: "Current Balance",
      Points: "Points",
      Level: "Level",
      PendingExchange: "Pending Exchange",
      ApprovedExchange: "Approved Exchange",
      ProcessingExchange: "Processing Exchange",
      CompletedExchange: "Completed Exchange",
      afflicationpoints: "Afflication points",
      Bonuspoints: "Bonus points",
      Exchangepoints: "Exchange points",
      CanceledExchange: "Canceled Exchange",
      RefundedExchange: "Refunded Exchange",
      RefusedExchange: "Refused Exchange",
      TransactionID: "Transaction ID",
      TimeCanbeCanceled: "Time Can be Canceled",
      PaymentProof: "Payment Proof",
      Amount: "Amount",
      Comment: "Comment",
      Status: "Status",
      NoPreviousWithdraw: "No Previous Withdraw",
      Reserve: "Reserve",
      YourTicket: "Your Ticket",
      invitionlink: "invition Link",
      Generate: "Generate",
      CopyLink: "Copy Link",
      Image: "Image",
      Name: "Name",
      NoPreviousTickets: "No Previous Tickets",
      userinvitedid: "User invited id",
      userinvitedusername: "User invited username",
      codeinvitedwith: "Code invited with",
      GotBonus: "Got Bonus",
      NoPreviousAfflications: "No Previous Afflications",
      Message: "Message",
      topNav: "For technical support or to complete the transfer faster, please contact us via WhatsApp",
      Recieve: "Recieve",
      TotalExchangesNumber: "Number Of Exchanges",
      TotalExchangesBalance: "Total Money Exchanged ",
      TotalExchangesNumberReq: "Number Of Exchanges Required",
      TotalExchangesBalanceReq: "Total Money Exchanged Required",
      Other: "Other",
      UpdateProfile: "Edit Profile",
      Pages: "Pages",
      Company: "Company",
      NumberOfExchanges: "Number Of Exchanges",
      TotalMoneyExchanged: "Total Money Exchanged ",
      ReedmedBalance: "Reedmed Balance",
      WaitingForApprovel: "Waiting For Approvel",
      FirstName: "First Name",
      EnterFirstName: "Enter First Name",
      LastName: "Last Name",
      EnterLastName: "Enter Last Name",
      Username: "Username",
      EnterUsername: "Enter User name",
      Address: "Address",
      EnterAddress: "Enter Address",
      Phone: "Phone",
      EnterPhone: "Enter Phone",
      Update: "Update",
      Login: "Sign in",
      Register: "Register",
      ForgotPassword: "Forgot Password",
      Enteryouremail: "Enteryouremail",
      SendResetLink: "SendResetLink",
      Enteryouremail: "Enter Your Email",
      SendResetLink: "Send Reset Link",
      Forgotyourpasswordd: "Forgot your password?",
      ClickHere: "Click Here",
      RegisterwithFacebook: "Register with Facebook",
      RegisterwithGoogle: "Register with Google",
      LogInWithFacebook: " Sign in With Facebook",
      LogInWithGoogle: "Sign in With Google",
      Telegram: "Telegram",
      terms_title:"Terms and Condations",
      terms: [
        { title: "1. Acceptance of Terms", text: "By accessing and using our website and services, you agree to comply with these terms and conditions. If you do not agree with any part of these terms, you must immediately stop using the website." },
        { title: "2. Services Provided", text: "Our website offers a service for exchanging money between electronic wallets (e.g., Skrill and PayPal). Users must register and provide accurate information to ensure transactions are completed securely and reliably." },
        { title: "3. Registration", text: "To access our services, you must create an account and provide accurate and up-to-date information. You are responsible for maintaining the confidentiality of your account and password and for all activities conducted under your account." },
        { title: "4. Fees and Payments", text: "We charge fees for each transaction. The fee amount will be clearly displayed before completing any exchange transaction. Payments are made using the available methods on the site." },
        { title: "5. Restrictions", text: `<ul>
            <li>Our services must not be used for illegal or fraudulent purposes.</li>
            <li>Users are prohibited from creating more than one account per person.</li>
            <li>Misuse or manipulation of the referral system to generate illegitimate profits is strictly forbidden.</li>
        </ul>` },
        { title: "6. Intellectual Property", text: "All content on the website, including text, designs, graphics, and logos, is protected by copyright and intellectual property laws. Copying or using these materials without our explicit permission is prohibited." },
        { title: "7. Limitation of Liability", text: "We are not liable for any financial losses resulting from the use or inability to use our services, including technical delays or errors. We are also not responsible for the accuracy of information provided by users or third parties." },
        { title: "8. Changes to Terms", text: "We reserve the right to modify these terms at any time. Users will be notified of any changes via the website. Continued use of the services after modifications signifies your acceptance of the updated terms." },
        { title: "9. Account Termination", text: "We reserve the right to terminate or suspend your account if any of these terms are violated or if misuse of the account or system is suspected." },
        { title: "10. Contact Us", text: "If you have any inquiries or require support, you can reach us via email or use the contact form available on the website." }
    ],
    policy_title:"Policies",
    policy: [
      {
        title: "1. Introduction",
        text: "This policy outlines the rules and regulations governing the use of our platform for exchanging money between digital wallets (such as Skrill, PayPal, etc.). By using our services, users agree to these terms."
      },
      {
        title: "2. Eligibility",
        text: "Users must be at least 18 years old and have the legal authority to operate the wallets used in transactions. Both wallets (sending and receiving) must be owned by the same individual."
      },
      {
        title: "3. Account Verification",
        text: "To ensure security and transparency, users must verify their identity and wallet ownership by providing necessary documentation such as ID proof, wallet screenshots, and any other required documents."
      },
      {
        title: "4. Transaction Fees",
        text: "A fee is charged for each exchange transaction. The fee amount will be displayed before the transaction is completed, and it may vary depending on the digital wallets and currencies involved."
      },
      {
        title: "5. Transaction Limits",
        text: "There may be limits on the amount that can be exchanged in a single transaction or within a certain period. These limits will be communicated to users prior to completing any transaction."
      },
      {
        title: "6. Security",
        text: "Users are responsible for maintaining the confidentiality of their account information and are liable for all activities that occur under their accounts."
      },
      {
        title: "7. Dispute Resolution",
        text: "In case of any disputes regarding transactions, users should contact our support team within 14 days of the transaction. We will make every effort to resolve disputes amicably."
      },
      {
        title: "8. Termination",
        text: "We reserve the right to suspend or terminate user accounts at our discretion if we suspect any fraudulent activity or violation of these terms."
      },
      {
        title: "9. Changes to Terms",
        text: "We may update these terms periodically. Users will be notified of any significant changes, and continued use of the services after such changes constitutes acceptance of the new terms."
      },
      {
        title: "10. Governing Law",
        text: "These terms and conditions are governed by the laws of [Your Country/Region]."
      },
    ],
    WhatsAppOr: "Enter your WhatsApp or Telegram contact",
    Get:"Amount Get"

    },
  },
  ar: {
    translation: {
      dir: "rtl",
      Home: "الصفحه الرئيسيه",
      About: "الشركه",
      contact: "للتواصل",
      FAQ: "الاسئله",
      profile: "الحساب",
      logout: "تسجيل الخروج",
      Services: "الخدمات",
      exchangeAgency: "وكالة لتبديل العملات",
      Discover: "اكتشف المزيد",
      Check: "تحقق من أسعار الصرف لدينا",
      Enter: "أدخل المبلغ الخاص بك",
      BeeChanger: "Changer Money",
      BeeChangerP: "مرحبًا بكم في منصتنا المتطورة لتحويل الأموال، حيث تلتقي الراحة بالأمان. مع معاملات سلسة بين جميع البنوك الكبرى، نفخر بأن نكون الخيار الأسهل والأكثر أمانًا المتاح. بالإضافة إلى ذلك، نقدم أسعارًا تنافسية لضمان حصولك على أفضل قيمة لأموالك.",
      Exchange: "بدل",
      from: "من",
      to: "إلى",
      provide: " نحن نقدم",
      dservices: "خدمات متنوعة",
      Features: "ميزات",
      Agency: "وكالتنا",
      platform: "تضمن منصتنا السهولة والأمان، وتوفر لك أفضل العروض والأسعار التنافسية.",
      ProfitableExchange: "تبادل مربح",
      High: "مستوى عالٍ من الموثوقية",
      Economic: "تبادل اقتصادي",
      Withdraw: "سحب الإحالات",
      WORKS: "كيف يعمل بى تشينجر",
      Quality: "نحن نهتم بالجودة",
      Qualitatively: "تغيير العملة عبر الإنترنت بجودة عالية",
      somethingWrong: "إذا حدث خطأ ما، سنعيد أموالك دائمًا",
      exchangeguarantee: "ضمان تبادل بنسبة 100%",
      Numerous: "تؤكد العديد من المراجعات الإيجابية على جودة عملنا العالية",
      loyalrates: "نحن دائمًا نقدم أسعارًا موالية، ونحن دائمًا منفتحون على التعاون، يرجى الاتصال بنا",
      providereliabilty: "نحن نوفر ليس فقط الموثوقية وأفضل الأسعار، ولكن أيضًا التبادل السريع",
      populardestinations: "نحن نقوم بتبادل الأموال في الوجهات الأكثر شعبية، يمكنك دائمًا تبادل أموالك",
      exchangedirections: "الكثير من اتجاهات التبادل",
      FastExchange: "تبادل سريع",
      Reviews: "التقييمات",
      and: "و",
      Opinions: "الآراء",
      feedback: "تعزز ملاحظات العملاء التزامنا بأن نكون الأفضل في الصناعة، مما يضمن أن يكون كل تبادل سلسًا وآمنًا ومفيدًا.",
      ContactUs: "اتصل بنا",
      Touch: "ابقى على تواصل",
      YourName: "اسمك",
      YourSurname: "اسم العائلة",
      YourUsername: "اللقب",
      Yourmail: "بريدك الإلكتروني",
      YourMessage: "رسالتك",
      Subject: "الموضوع",
      SendMessageNow: "أرسل الرسالة الآن",
      OurVision: "رؤيتنا",
      GoodService: "خدمة جيدة",
      ForCommunity: "من أجل المجتمع",
      LongDevelopment: "التنمية على المدى الطويل",
      SavePlanet: "إنقاذ كوكبنا",
      HelpPeople: "مساعدة الناس",
      OurMision: "مهمتنا",
      ChangeHabits: "تغيير العادات",
      BestQuality: "أفضل جودة",
      ThinkBigger: "فكر كبيرا، اعمل أكبر",
      Stability: "الاستقرار والكفاءة",
      Safer: "حياة أكثر أمانًا وأفضل",
      Promise: "وعدنا",
      SustainableRelationship: "العلاقة المستدامة",
      RenewCommitment: "تجديد التزامنا",
      ProvideSolution: "توفير أفضل الحلول",
      ProfitableRelationship: "العلاقة الربحية",
      AdaptNeeds: "التكيف مع احتياجات الناس",
      Transaction: "معاملة",
      Support: "الدعم",
      Easy: "سهل",
      Fast: "سريع",
      Policy: "سياسة",
      Security: "الأمان",
      OurContacts: "طرق الاتصال الخاصة بنا",
      AddressInfo: " العنوان",
      Cairo: "القاهرة، مصر",
      PhoneCalls: " جهات الاتصال",
      EMailAddress: " البريد الإلكتروني",
      MostAsked: "أكثر الأسئلة تكراراً",
      Questions: "",
      Here: "هنا",
      StepsSuccess: "أربع خطواتنا نحو النجاح",
      HappyClients: "عملاء سعداء",
      quickanswers: "نقدم إجابات سريعة للأسئلة الشائعة حول خدماتنا",
      SendReview: "إرسل تقييمك",
      AddReview: "أضف تقييمك",
      Transactions: "كل معاملة تتم بدقة وعناية، مما يضمن عملية سلسة وآمنة من البداية إلى النهاية. ثق بنا للتعامل مع تبادلاتك بكفاءة وموثوقية."
      , SecurePayment: "تضمن منصتنا طرق دفع آمنة، مما يوفر راحة البال لجميع معاملاتك. نحن نعطي الأولوية لسلامتك وخصوصيتك، مما يضمن حماية معلوماتك المالية دائمًا."
      , UniversalAccess: "اختبر الوصول العالمي مع منصتنا، مما يتيح لك إجراء المعاملات من أي مكان في العالم. تم تصميم خدماتنا لتكون مريحة وسهلة الوصول بغض النظر عن مكانك."
      , Policy: "سياساتنا شفافة وتركز على العملاء، ومصممة لضمان تجربة عادلة وآمنة لجميع المستخدمين. نحن ملتزمون بالالتزام بأعلى معايير النزاهة والموثوقية."
      , Transaction1: "المعاملة",
      SecurePayment1: "دفع آمن",
      UniversalAccess1: "الوصول العالمي",
      Policy1: "السياسة",
      terms: "الشروط",
      Follow: "تابع",
      Password: "كلمه المرور",
      Donthave: "ليس لديك حساب؟",
      CreateNow: "أنشئ الآن",
      YourPhone: "هاتفك",
      Register: "تسجيل",
      HaveAccount: "هل لديك حساب؟",
      LoginHere: "تسجيل الدخول هنا",
      SignIn: "تسجيل الدخول",
      Send: "ارسال",
      ReceiveAmount: "استلام المبلغ",
      Receive: "استلام",
      Next: "التالي",
      Wallet: "المحفظة",
      ReceiveWallet: "  محفظه الاستلام او  المعرف   او البريد الالكترونى او اسم المستخدم",
      Proofpayment: " ارفاق صوره",
      ConfirmPassword: "تاكيد كلمه المرور",
      TransactionsNav: "المعاملات",
      Blogs: "المدونات",
      Bonus: "البونص",
      Dashbard: "لوحة التحكم",
      Withdraw: "سحب",
      NewWithdraw: "سحب جديد",
      AllWithdraw: "سجل السحوبات",
      Tickets: "تذاكر",
      NewTicket: "تذكرة جديدة",
      PendingTransactions: "المعاملات المعلقة",
      ProcessingTransactions: "المعاملات الجارية",
      CompletedTransactions: "المعاملات المكتملة",
      CanceledTransactions: "المعاملات الملغاة",
      RefundedTransactions: "المعاملات المستردة",
      AllTransactions: "كل المعاملات",
      InviteFriends: "ادعو صديق ",
      Afflication: "الإحالة",
      Dashboard: "لوحه التحكم",
      Exchanger: "المستبدل",
      SendFrom: "إرسال من",
      SendAmount: "المبلغ المرسل",
      GetTo: "الحصول على",
      GetAmount: "المبلغ المستلم",
      Date: "التاريخ",
      NoPrevioustransactions: "لا توجد معاملات سابقة",
      RECENT: "الأحدث",
      ReadMore: "اقرأ المزيد",
      Search: "بحث",
      Withraw: "اسحب",
      fromYour: "من",
      Balance: "الرصيد",
      CurrentBalance: "الرصيد الحالي",
      Points: "النقاط",
      Level: "المستوى",
      PendingExchange: "تبادل معلق",
      ApprovedExchange: "تبادل موافق عليه",
      ProcessingExchange: "تبادل قيد المعالجة",
      CompletedExchange: "تبادل مكتمل",
      afflicationpoints: "نقاط الدعوة",
      Bonuspoints: "نقاط المكافأة",
      Exchangepoints: "نقاط التبادل",
      CanceledExchange: "تبادل ملغى",
      RefundedExchange: "تبادل مسترد",
      RefusedExchange: "تبادل مرفوض",
      TransactionID: "رقم المعاملة",
      TimeCanbeCanceled: "الوقت الذي يمكن فيه الإلغاء",
      PaymentProof: "إثبات الدفع",
      Amount: "المبلغ",
      Comment: "التعليق",
      Status: "الحالة",
      NoPreviousWithdraw: "لا توجد سحوبات سابقة",
      Reserve: "احجز",
      YourTicket: "تذكرتك",
      invitionlink: "رابط الدعوة",
      Generate: "انشأ",
      CopyLink: "نسخ الرابط",
      CopyLink: "نسخ الرابط",
      Image: "صورة",
      Name: "الاسم",
      NoPreviousTickets: "لا توجد تذاكر سابقة",
      userinvitedid: "معرف المستخدم المدعو",
      userinvitedusername: "اسم المستخدم المدعو",
      codeinvitedwith: "الرمز المستخدم للدعوة",
      GotBonus: "تم الحصول على مكافأة",
      NoPreviousAfflications: "لا توجد دعوات سابقة",
      Message: "رسالتك",
      topNav: "للدعم الفنى او لاكمال التحويل بشكل اسرع يرجى التواصل معنا عبر الواتساب",
      Recieve: "استقبال",
      Other: "اخرى",
      UpdateProfile: "تعديل الملف الشخصى",
      Pages: "الصفحات",
      Company: "الشركه",
      NumberOfExchanges: "عدد التبادلات",
      TotalMoneyExchanged: "إجمالي المال المتبادل",
      ReedmedBalance: "الرصيد المسترد",
      WaitingForApprovel: "في انتظار الموافقة",
      FirstName: "الاسم الأول",
      EnterFirstName: "أدخل الاسم الأول",
      LastName: "الاسم الأخير",
      EnterLastName: "أدخل الاسم الأخير",
      Username: "اسم المستخدم",
      EnterUsername: "أدخل اسم المستخدم",
      Address: "العنوان",
      EnterAddress: "أدخل العنوان",
      Phone: "رقم الهاتف",
      EnterPhone: "أدخل رقم الهاتف",
      Update: "تحديث",
      Login: "تسجيل الدخول",
      Register: "انشاء حساب",
      SendResetLink: "إرسال رابط إعادة التعيين",
      Enteryouremail: "أدخل بريدك الإلكتروني",
      SendResetLink: "إرسال رابط إعادة التعيين",
      Forgotyourpasswordd: "هل نسيت كلمة المرور؟",
      ClickHere: "اضغط هنا",
      ForgotPassword: "نسيت كلمه المرور ",
      RegisterwithFacebook: "سجل باستخدام فيسبوك",
      RegisterwithGoogle: "سجل باستخدام جوجل",
      LogInWithFacebook: "تسجيل الدخول باستخدام فيسبوك",
      LogInWithGoogle: "تسجيل الدخول باستخدام جوجل",
      Loading: "يرسل",
      Telegram: "تليجرام",
      terms_title: "الشروط والأحكام",
      terms: [
        { title: "1. قبول الشروط", text: "من خلال الوصول إلى واستخدام موقعنا وخدماتنا، فإنك توافق على الالتزام بهذه الشروط والأحكام. إذا كنت لا توافق على أي جزء من هذه الشروط، يجب عليك التوقف فورًا عن استخدام الموقع." },
        { title: "2. الخدمات المقدمة", text: "يوفر موقعنا خدمة تبادل الأموال بين المحافظ الألكترونية (مثل Skrill و PayPal). يجب على المستخدمين التسجيل وتقديم معلومات صحيحة لضمان إتمام المعاملات بشكل آمن وموثوق." },
        { title: "3. التسجيل", text: "للاستفادة من خدماتنا، يجب عليك إنشاء حساب وتقديم معلومات دقيقة وحديثة. أنت مسؤول عن الحفاظ على سرية حسابك وكلمة المرور الخاصة بك، وأي أنشطة تتم باستخدام حسابك." },
        { title: "4. الرسوم والمدفوعات", text: "نحن نفرض رسومًا على كل معاملة. سيتم عرض قيمة الرسوم بوضوح قبل إتمام أي عملية تبادل. يتم دفع الرسوم باستخدام الوسائل المتاحة على الموقع." },
        {
          title: "5. القيود", text: `<ul>
                        <li>لا يجوز استخدام خدماتنا لأي أغراض غير قانونية أو احتيالية.</li>
                        <li>يُمنع على المستخدمين إنشاء أكثر من حساب واحد لنفس الشخص.</li>
                        <li>يُمنع إساءة استخدام نظام الإحالات أو التلاعب به لتحقيق أرباح غير مشروعة.</li>
                    </ul>` },
        { title: "6. الملكية الفكرية", text: "جميع المحتويات الموجودة على الموقع بما في ذلك النصوص، التصميمات، الرسومات، والشعارات محمية بموجب قوانين حقوق النشر والملكية الفكرية. يُحظر نسخ أو استخدام هذه المواد دون إذن صريح منا." },
        { title: "7. حدود المسؤولية", text: "نحن لسنا مسؤولين عن أي خسائر مالية ناتجة عن استخدام أو عدم القدرة على استخدام خدماتنا، بما في ذلك التأخيرات التقنية أو الأخطاء. كما أننا لا نتحمل مسؤولية صحة أو دقة المعلومات المقدمة من المستخدمين أو من أطراف ثالثة." },
        { title: "8. التغييرات في الشروط", text: "نحتفظ بالحق في تعديل هذه الشروط في أي وقت. سيتم إخطار المستخدمين بأي تغييرات عبر الموقع. استمرار استخدامك للخدمات بعد التعديلات يعني موافقتك على الشروط الجديدة." },
        { title: "9. إلغاء الحساب", text: "نحتفظ بالحق في إلغاء أو تعليق حسابك إذا تم انتهاك أي من هذه الشروط أو إذا تم الاشتباه في إساءة استخدام الحساب أو النظام." },
        { title: "10. اتصال بنا", text: "إذا كان لديك أي استفسارات أو تحتاج إلى دعم، يمكنك التواصل معنا عبر البريد الإلكتروني أو استخدام نموذج الاتصال على الموقع." }
      ],
    policy_title:"السياسات",

      policy: [
        {
          title: "1. المقدمة",
          text: "تحدد هذه السياسة القواعد واللوائح التي تحكم استخدام منصتنا لتبادل الأموال بين المحافظ الإلكترونية (مثل Skrill و PayPal وما إلى ذلك). باستخدام خدماتنا، يوافق المستخدمون على هذه الشروط."
        },
        {
          title: "2. الأهلية",
          text: "يجب أن يكون عمر المستخدمين 18 عامًا على الأقل، وأن يكون لديهم السلطة القانونية لاستخدام المحافظ في المعاملات. كما يجب أن تكون المحافظ المستخدمة (المُرسلة والمُستلمة) مملوكة لنفس الشخص."
        },
        {
          title: "3. التحقق من الحساب",
          text: "لضمان الأمان والشفافية، يجب على المستخدمين التحقق من هويتهم وملكية المحافظ عبر تقديم المستندات المطلوبة مثل إثبات الهوية، لقطات شاشة من المحافظ، وأي وثائق أخرى ضرورية."
        },
        {
          title: "4. رسوم المعاملات",
          text: "يتم فرض رسوم على كل عملية تبادل. يتم عرض قيمة الرسوم قبل إتمام المعاملة وقد تختلف بناءً على المحافظ الإلكترونية والعملات المتداولة."
        },
        {
          title: "5. حدود المعاملات",
          text: "قد توجد حدود على المبلغ الذي يمكن تبادله في عملية واحدة أو خلال فترة معينة. سيتم إبلاغ المستخدمين بهذه الحدود قبل إتمام أي معاملة."
        },
        {
          title: "6. الأمان",
          text: "المستخدمون مسؤولون عن الحفاظ على سرية معلومات حساباتهم وهم مسؤولون عن جميع الأنشطة التي تحدث تحت حساباتهم."
        },
        {
          title: "7. حل النزاعات",
          text: "في حال حدوث أي نزاعات بشأن المعاملات، يجب على المستخدمين الاتصال بفريق الدعم لدينا في غضون 14 يومًا من المعاملة. سنبذل قصارى جهدنا لحل النزاعات بشكل ودي."
        },
        {
          title: "8. إنهاء الخدمة",
          text: "نحتفظ بالحق في تعليق أو إنهاء حسابات المستخدمين وفقًا لتقديرنا إذا اشتبهنا في أي نشاط احتيالي أو انتهاك لهذه الشروط."
        },
        {
          title: "9. تغييرات على الشروط",
          text: "قد نقوم بتحديث هذه الشروط بشكل دوري. سيتم إبلاغ المستخدمين بأي تغييرات كبيرة، ويعتبر استمرار استخدام الخدمات بعد هذه التغييرات قبولًا بالشروط الجديدة."
        },
        {
          title: "10. القانون الحاكم",
          text: "تخضع هذه الشروط والأحكام لقوانين [بلدك/منطقتك]."
        },

      ],
      WhatsAppOr:"أدخل رقم الواتساب أو اسم مستخدم التليجرام",
      Get:"المبلغ المستلم"




    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // Default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
