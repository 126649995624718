import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import { createUserWithEmailAndPassword } from '@firebase/auth';
import { auth, facebookProvider, googleProvider } from './firebase'; // Ensure correct imports
import { signInWithPopup } from "@firebase/auth";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Register() {
  const [isLogin, setIsLogin] = useState(false);
  const [user, setUser] = useState({
    f_name: "",
    s_name: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "", // New state for confirm password
    address: "jj",
    country: "",

    phone: ""
  });
  const [codeInvitedWith, setCodeInvitedWith] = useState("");
  const [userWhoInvites, setUserWhoInvites] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);
  const [showPassword, setShowPassword] = useState(false); // Track password visibility
  const [showPassword2, setShowPassword2] = useState(false); // Track password visibility

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    var name = localStorage.getItem("name");
    if (name) {
      window.location.href = '/';
    }

    const searchParams = new URLSearchParams(location.search);
    const invitationCode = searchParams.get("invition_code");
    const uId = searchParams.get("u_id");

    if (invitationCode) {
      setCodeInvitedWith(invitationCode);
    }
    if (uId) {
      setCodeInvitedWith("123");

      setUserWhoInvites(uId);
    }
  }, [location.search]);

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const login = async (provider) => {
    try {
      const result = await signInWithPopup(auth, provider);
      const userData = result.user;
      setIsLogin(true);
      setUser({
        f_name: '',
        s_name: '',
        username: '',
        address: 'jj',
        phone: '',
        email: userData.email || userData.uid,
        country:''
      });

    } catch (error) {
      console.log(`login error ${error}`);
      setIsLogin(false);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // if (!captchaToken) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Captcha Required',
    //     text: 'Please complete the CAPTCHA.'
    //   });
    //   return;
    // }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(user.email)) {
    Swal.fire({
      icon: 'error',
      title: 'Invalid Email',
      text: 'Please enter a valid email address.',
    });
    return;
  }

  // const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
  // if (!passwordRegex.test(user.password)) {
  //   Swal.fire({
  //     icon: 'error',
  //     title: 'Weak Password',
  //     text: 'Password must be at least 8 characters long, contain a number, an uppercase letter, and a special character.',
  //   });
  //   return;
  // }

  if (user.password !== user.confirmPassword) {
    Swal.fire({
      icon: 'error',
      title: 'Passwords do not match',
      text: 'Please check and try again.'
    });
    return;
  }

  // if (!user.f_name || !user.s_name || !user.username || !user.email || !user.password || !user.phone || !user.country) {
  //   Swal.fire({
  //     icon: 'error',
  //     title: 'Incomplete Form',
  //     text: 'Please fill in all the required fields.',
  //   });
  //   return;
  // }

  const phoneRegex = /^\d{10,15}$/; 
  if (!phoneRegex.test(user.phone)) {
    Swal.fire({
      icon: 'error',
      title: 'Invalid Phone Number',
      text: 'Please enter a valid phone number (digits only).',
    });
    return;
  }


    if (!isLogin) {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, user.email, user.password);
        const newUser = userCredential.user;
        saveUserData(newUser.uid);
      } catch (error) {
        console.error("Error during registration:", error.message);
        Swal.fire({
          icon: 'error',
          title: 'Registration failed',
          text: 'Please try again.'
        });
      }
    } else {
      saveUserData(auth.currentUser.uid);
    }
  };

  const saveUserData = async (userId) => {
    try {
      const formData = new FormData();
      formData.append("f_name", user.f_name);
      formData.append("s_name", user.s_name);
      formData.append("username", user.username);
      formData.append("email", user.email); // Include email for email/password registration
      formData.append("password", user.password); // Include password for email/password registration
      formData.append("address", "jjj");
      formData.append("user_id", userId);
      formData.append("phone", user.phone);
      formData.append("country", user.country);
      formData.append("code_invited_with", codeInvitedWith);
      formData.append("who_invites", userWhoInvites);

      const response = await axios.post("https://changermoney.com/exchange/dashboard/users/readAll.php" , {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,      "Content-Type": "application/json"                    }}, formData);

      if (response.data) {
        Swal.fire({
          icon: 'success',
          title: 'Registration successful',
        });
        
        window.localStorage.setItem("name", response.data.username);
        window.localStorage.setItem("id", response.data.id);
        window.location.href = '/';
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Registration failed',
          text: 'Please try again.'
        });
      }
    } catch (error) {
      console.error("Error during registration:", error);
      Swal.fire({
        icon: 'error',
        title: 'Registration failed',
        text: error?error:'Please try again.'
      });
    }
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  return (
    <>
      <section className="main-banner signinform" id="top">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <img src="images/loginn.png" alt="" width="100px" />
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="singles-contacts-box">
                <form onSubmit={(e) => onSubmit(e)} id="contact-form">
                  {!isLogin && (
                    <>
                      <FacebookLoginButton className="facea"
                        onClick={() => login(facebookProvider)}>
                        {t("RegisterwithFacebook")}
                      </FacebookLoginButton>

                      <GoogleLoginButton className="googlea" onClick={() => login(googleProvider)}>
                        {t("RegisterwithGoogle")}
                      </GoogleLoginButton>

                    </>
                  )}
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <fieldset className="form_box">
                        <input
                          type="text"
                          placeholder={t("YourName")}
                          name="f_name"
                          required
                          className="form-control"
                          value={user.f_name}
                          onChange={(e) => onInputChange(e)}
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <fieldset className="form_box">
                        <input
                          type="text"
                          placeholder={t("YourSurname")}
                          name="s_name"
                          required
                          value={user.s_name}
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <fieldset className="form_box">
                        <input
                          type="text"
                          placeholder={t("YourUsername")}
                          name="username"
                          required
                          value={user.username}
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                        />
                      </fieldset>
                    </div>
                    {!isLogin && (
                      <>
                        <div className="col-lg-12 col-md-12">
                          <div className="form_box">
                            <fieldset className="form_box">
                              <input
                                type="email"
                                placeholder={t("Yourmail")}
                                name="email"
                                value={user.email}
                                required
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                              />
                            </fieldset>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form_box  password-container">
                            <fieldset className="form_box">
                              <input
                                type={showPassword ? "text" : "password"}
                                placeholder={t("Password")}
                                name="password"
                                required
                                className="form-control"
                                value={user.password}
                                onChange={(e) => onInputChange(e)}
                              />
                            </fieldset>
                            <span className="toggle-password" onClick={toggleShowPassword}>
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form_box  password-container">
                            <fieldset className="form_box">
                              <input
                                type={showPassword2 ? "text" : "password"}
                                required
                                placeholder={t("ConfirmPassword")} // Placeholder for confirm password
                                name="confirmPassword"
                                className="form-control"
                                value={user.confirmPassword}
                                onChange={(e) => onInputChange(e)}
                              />
                            </fieldset>
                            <span className="toggle-password" onClick={toggleShowPassword2}>
                              {showPassword2 ? <FaEyeSlash /> : <FaEye />}
                            </span>
                          </div>

                        </div>

                      </>
                    )}
                    <div className="col-lg-12 col-md-12">
                      <div className="form_box">
                        <fieldset className="form_box">
                          <input
                            type="text"
                            placeholder={t("YourPhone")}
                            required
                            name="phone"
                            value={user.phone}
                            className="form-control"
                            onChange={(e) => onInputChange(e)}
                          />
                        </fieldset>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form_box">
                        <fieldset className="form_box">
                          <input
                            type="text"
                            required
                            placeholder={t("Country")}
                            name="country"
                            className="form-control"
                            value={user.country}
                            onChange={(e) => onInputChange(e)}
                          />
                        </fieldset>
                      </div>
                    </div>
                    {/* <div className="col-lg-12 col-md-12">
                      <div className="form_box">
                        <fieldset className="form_box">
                          <input
                            type="text"
                            required
                            placeholder={t("AddressInfo")}
                            name="address"
                            className="form-control"
                            value={user.address}
                            onChange={(e) => onInputChange(e)}
                          />
                        </fieldset>
                      </div>
                    </div> */}
                    {/* {!isLogin && ( */}
                    <div className="col-lg-12 col-md-12">
                      <ReCAPTCHA
                        sitekey="6LfTGQ0qAAAAAHdeT2KAGVLw24BrLcwwVH62ztvd" // Replace with your reCAPTCHA site key
                        onChange={onCaptchaChange}
                      />
                    </div>
                    {/* )} */}
                  </div>
                  <div className="form-button">
                    <button className="signinbtn">{t("Register")}</button>
                  </div>
                  <h5>
                    {t("HaveAccount")} <Link to="/Signin"> {t("LoginHere")}</Link>
                  </h5>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Register;
