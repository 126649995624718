import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import i18n from './i18n';
import { useTranslation } from 'react-i18next';

const ChangePointsToDollars = () => {
    const [points, setPoints] = useState(0);
    const [message, setMessage] = useState('');

    const handlePointsChange = (e) => {
        setPoints(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const user_id = localStorage.getItem('id');
        try {
            let formData = new FormData();
            formData.append("user_id", user_id);
            formData.append("points", Number(points));


            const response = await axios({
                method: "post",
                url: "https://changermoney.com/exchange/dashboard/points_dollars/readAll.php" , 
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
              "Content-Type": "application/json" 
                    },
                data: formData,
            });

            if (response.data.status == "success") {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.data.message,
                });
                // const result = await response;
                // console.log(response.data.message);
                setMessage(response.data.message);
            } else if (response.data.status == "error") {
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: response.data.message,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Submission Failed',
                });
            }
        } catch (error) {
            console.error("Error during Review:", error);
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Submission failed',
            });
        }

    };
    const { t } = useTranslation();
    const [openSections, setOpenSections] = useState([]);
    const [PointsDollars, setPointsDollars] = useState([]);
    const [loading, setLoading] = useState(true);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language, (err, t) => {
            if (err) return console.log("Error loading language:", err);
            console.log("Language changed to:", language);
        });
    };


    useEffect(() => {
        const loadPointsDollars = async () => {
            try {
                const result = await axios.get(
                    "https://changermoney.com/exchange/dashboard/points_dollars/pointsRead.php"
                );
                console.log("Fetched social data:", result.data);
                setPointsDollars(result.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching social media data:", error);
                setLoading(false);
            }
        };

        loadPointsDollars();
    }, []);

    return (
        <section className="main-banner" id="top">
            <div className="container">

                <div className="row justify-content-center">
                    <div className="col-lg-9 col-md-9">
                        <div className="singles-contacts-box">
                            <div id="second-form" className="invitioncard py-5 text-center">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-heading">
                                            <h2><em>{PointsDollars[4] ? PointsDollars[4].points_req_one : null} Points <span>=</span> 1 Dollar </em>  </h2>
                                        </div>
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <label>
                                        Points to exchange:
                                        <input type="number" value={points} onChange={handlePointsChange} />
                                    </label>
                                    <button type="submit">Exchange Points</button>
                                </form>
                                {/* {message && <p>{message}</p>} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default ChangePointsToDollars;
