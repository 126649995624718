import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import Swal from 'sweetalert2'; // Import SweetAlert
import { Footer } from "rsuite";
import parse from 'react-html-parser';

function LevelReq() {
  const { id } = useParams();
  const [levels, setlevels] = useState([]);
  const { t } = useTranslation();
  const [levels_req, setlevelsReq] = useState({
    description: "",
  });
  const { description } = levels_req;
  const [user, setUser] = useState("");

  useEffect(() => {
    var user_id = localStorage.getItem("id");
    if (!user_id) {
      window.location.href = '/';
    }
    setUser(user_id);
    loadlevels();
  }, []);

  const loadlevels = async () => {
    try {
      const result = await axios.get(
        "https://changermoney.com/exchange/dashboard/levels/readAll.php", {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,      "Content-Type": "application/json"        }
      }
      );
      setlevels(result.data.reverse());
    } catch (error) {
      console.error("Error loading levelses:", error);
    }
  };

  const onInputChange = (e) => {
    setlevelsReq({ ...levels_req, [e.target.name]: e.target.value });
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      let formData = new FormData();
      formData.append("user_id", user);
      formData.append("level_id", id);
      formData.append("description", levels_req.description);
      const response = await axios({
        method: "post",
        url: "https://changermoney.com/exchange/dashboard/level_req/readAll.php",
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
            "Content-Type": "multipart/form-data"
        },
        data: formData
    });
    

      if (response.data) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Thanks, we will respond you',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: 'Submission failed',
        });
      }
    } catch (error) {
      console.error("Error during Review:", error);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Submission failed',
      });
    }
  };

  return (
    <div>
      <Container className="SHOPALLL">
        <Row>
          {levels.map((level) => (
            <>
              {level.id === id && (
                <Col lg="9" md="9" sm="9" className="bllogsdetail" key={level.id}>
                  <img
                    src={`https://changermoney.com/exchange/uploads/levels/${level.image}`}
                    alt=""
                  />
                  <h3>{t("dir") === "ltr" ? level.title_en : level.title_ar}</h3>
                  <p>
                    {t("dir") === "ltr" ? parse(level.description_en) : parse(level.description_ar)}
                  </p>
                  <div>
                    <form onSubmit={submit} id="contact-form" className=" py-5 my-5">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="section-heading">
                            <h2><em>{t("have")}</em> {t("You")} <span> {t("Finished")}</span> ?!</h2>
                          </div>
                        </div>
                        <div className="row py-5">

                          <div className="col-lg-12 col-md-12">
                            <p className="px-2 py-2">{t("Any Comment?")}</p>

                            <div className="form_box">
                              <textarea placeholder={t("Comment")} type="text" name="description" value={levels_req.description} onChange={onInputChange} className='form-control' ></textarea>
                            </div>
                          </div>
                          <div className="form-button col-md-6 m-auto">
                            <button className="signinbtn" type="submit">{t("Send")}</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </Col>
              )}
            </>
          ))}


        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default LevelReq;
