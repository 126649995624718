import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "./firebase";
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";
import i18n from "./i18n";

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const { t } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log("Error loading language:", err);
      console.log("Language changed to:", language);
    });
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      // Send the password reset email
      await sendPasswordResetEmail(auth, email, {
        url: 'https://www.changermoney.com/reset-password?oobCode=YOUR_OOB_CODE' // URL where users will be redirected
      });
      Swal.fire({
        icon: 'success',
        title: 'Password reset link sent!',
        text: 'Check your email for the reset link.',
      });
    } catch (error) {
      console.error("Error sending password reset email:", error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    }
  };

  return (
    <section className="main-banner" id="top">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9 col-md-9">
            <div className="singles-contacts-box">
              <div id="second-form" className="invitioncard py-5 text-center">
                <div className="forgot-password-form">
                  <h2>{t("ForgotPassword")}</h2>
                  <form onSubmit={handleForgotPassword}>
                    <input
                      type="email"
                      placeholder={t("Enteryouremail")}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <button type="submit">{t("SendResetLink")}</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgotPassword;
