import React, { Component, useEffect, useRef, useState } from "react";

import Footer from "./Footer";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import { Link } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import { FaPhoneAlt, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import Swal from "sweetalert2";
import axios from "axios";

function ContactUs() {
  const form = useRef();
  const { t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log("Error loading language:", err);
      // console.log("Language changed to:", language);
    });
  };
  const sendEmail = (e) => {

    e.preventDefault();
    emailjs
      .sendForm(
        "service_fra71n6",
        "template_wmc4azd",
        form.current,
        "WDG9NePNUltT6gljD"
      )
      .then(
        (result) => {
          // console.log(result.text);
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Thanks, we will respond you',
          });
        },
        (error) => {
          // console.log(error.text);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          });
        }
      );
    e.target.reset();
  };
  const [socials, setSocials] = useState([]);
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    const loadSocials = async () => {
      try {
        const result = await axios.get(
          "https://changermoney.com/exchange/dashboard/social/readAll.php", {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
              "Content-Type": "application/json"

                       }
        }
        );
        console.log("Fetched social data:", result.data);
        setSocials(result.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching social media data:", error);
        setLoading(false);
      }
    };

    loadSocials();
  }, []);
  return (
    <>
      <section className="breadcumb-area d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breacumb-content wow fadeInUp " data-wow-duration="2s">
                <div className="breadcumb-title">
                  <h1>{t("contact")}</h1>
                </div>
                <div className="breadcumb-content-text">
                  <Link to="/">
                    <span>{t("Home")}</span> {t("contact")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="info-area">
        <div className="container">
          <h2 className="wow fadeInUp " data-wow-duration="2s">{t("OurContacts")}</h2>
          <div className="row">
            <div className="col-lg-6 col-md-6 wow bounceInLeft " data-wow-duration="2s">
              <div className="single-info-box">
                <div className="info-icon">
                  <CiLocationOn />

                </div>
                <div className="info-area-title">
                  <h3>{t("AddressInfo")}</h3>
                  <p>
                    {socials[6]?.status === '1' && socials[6]?.link}

                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 wow fadeInUp " data-wow-duration="2s">
              <div className="single-info-box">
                <div className="info-icon">
                  <FaWhatsapp />
                </div>
                <div className="info-area-title">
                  <h3>{t("Whatsapp")}</h3>
                  <p>
                    {socials[3]?.status === '1' && <a href={`https://wa.me/${socials[3]?.link}`} target="_blank">{socials[4]?.link}</a>}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 wow fadeInUp " data-wow-duration="2s">
              <div className="single-info-box">
                <div className="info-icon">
                  <FaTelegramPlane />
                </div>
                <div className="info-area-title">
                  <h3>{t("Telegram")}</h3>
                  <p>
                    {socials[2]?.status === '1' && <a href={`https://t.me/${socials[2]?.link}`} target="_blank">{socials[3]?.link}</a>}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 wow bounceInRight " data-wow-duration="2s">
              <div className="single-info-box">
                <div className="info-icon">
                  <MdOutlineMailOutline />
                </div>
                <div className="info-area-title">
                  <h3>{t("EMailAddress")}</h3>
                  <p>
                    support@changermoney.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="contact-us section" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-us-content">
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <div className="contact-thumb wow bounceInLeft" data-wow-duration="2s">
                      <img src="images/contact.png" alt="" />
                    </div>
                  </div>
                  <div className="col-lg-7 wow bounceInRight" data-wow-duration="2s">
                    <form id="contact-form" ref={form} onSubmit={sendEmail} method="POST">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="section-heading">
                            <h2><em>{t("ContactUs")}</em> {t("and")} <span> {t("Touch")}</span></h2>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <fieldset>
                            <input type="name" name="name" id="name" placeholder={t("FirstName")} required />
                          </fieldset>
                        </div>
                        <div className="col-lg-6">
                          <fieldset>
                            <input type="surname" name="surname" id="surname" placeholder={t("YourSurname")} required />
                          </fieldset>
                        </div>
                        <div className="col-lg-6">
                          <fieldset>
                            <input type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder={t("Yourmail")} required="" />
                          </fieldset>
                        </div>
                        <div className="col-lg-6">
                          <fieldset>
                            <input type="subject" name="subject" id="subject" placeholder={t("Subject")} />
                          </fieldset>
                        </div>
                        <div className="col-lg-12">
                          <fieldset>
                            <textarea name="message" id="message" placeholder={t("YourMessage")}></textarea>
                          </fieldset>
                        </div>
                        <div className="col-lg-12">
                          <fieldset>
                            <button type="submit" id="form-submit" className="orange-button">{t("SendMessageNow")}</button>
                          </fieldset>
                        </div>
                      </div>
                    </form>
                    <div className="more-info">
                      <div className="row">

                        <div className="col-lg-4">
                          <div className="info-item">
                            <i className="fa fa-envelope"></i>
                            <h4><a href="#">support@changermoney.com</a></h4>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <Footer />
    </>
  );
}

export default ContactUs;
