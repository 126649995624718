import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import parse from 'react-html-parser';

const Faqs = () => {
  const [mounts, setMounts] = useState({});
  const [questions, setQuestions] = useState([]);
  const { t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log("Error loading language:", err);
      // console.log("Language changed to:", language);
    });
  };

  const toggleMount = (key) => {
    setMounts({
      ...mounts,
      [key]: !mounts[key],
    });
  };

  useEffect(() => {
    loadQuestions();
  }, []);

  const loadQuestions = async () => {
    try {
      const result = await axios.get(
        "https://changermoney.com/exchange/dashboard/questions/readAll.php" , {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,      "Content-Type": "application/json"                    }}
      );
      setQuestions(result.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  return (
    <>
      <section className="breadcumb-area d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breacumb-content wow fadeInUp" data-wow-duration="2s">
                <div className="breadcumb-title">
                  <h1>{t("FAQ")}</h1>
                </div>
                <div className="breadcumb-content-text">
                  <Link to="/">
                    <span>{t("Home")}</span> {t("FAQ")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="page-heading">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 align-self-center">
              <div className="caption header-text wow bounceInLeft" data-wow-duration="2s">
                <h6>{t("exchangeAgency")}</h6>
                <div className="line-dec"></div>
                <h4>{t("MostAsked")} <em>{t("Questions")}</em> {t("Here")} <em></em></h4>
              </div>
            </div>
            <div className="col-lg-5 wow bounceInRight" data-wow-duration="2s">
              <img src="images/faqs-image.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="happy-steps">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 wow fadeInUp" data-wow-duration="2s">
              <h2>{t("StepsSuccess")} {t("and")} {t("HappyClients")}</h2>
            </div>
            <div className="col-lg-12 wow fadeInUp" data-wow-duration="2s">
              <div className="steps">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="item">
                      <img src="assets/images/services-01.jpg" alt="" />
                      <h4>{t("FastExchange")}</h4>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="item">
                      <img src="assets/images/services-02.jpg" alt="" />
                      <h4>{t("High")}</h4>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="item">
                      <img src="assets/images/services-03.jpg" alt="" />
                      <h4>{t("Withdraw")}</h4>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="item last-item">
                      <img src="assets/images/services-04.jpg" alt="" />
                      <h4>{t("ProfitableExchange")}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="most-asked section">
        <div className="container">
          <div className="row wow bounceInLeft" data-wow-duration="2s">
            <div className="col-lg-12">
              <div className="section-heading">
                <h2><em>{t("MostAsked")}</em> <span>{t("Questions")}</span></h2>
                <div className="line-dec"></div>
                <p>{t("quickanswers")}</p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 wow bounceInLeft" data-wow-duration="2s">
              <div className="accordions is-first-expanded">
                {questions.map((item, index) => (
                  <div key={index}>
                    <article className="accordion">
                      <div className="accordion-head" onClick={() => toggleMount(`mount${index}`)}>
                        <span>{t("dir") === "ltr" ? item.question_en : item.question_ar}</span>
                        <span className="icon">{mounts[`mount${index}`] ? "-" : "+"}</span>
                      </div>
                    </article>
                    {mounts[`mount${index}`] && (
                      <div className="content">
                        <p>{t("dir") === "ltr" ? parse(item.answer_en) : parse(item.answer_ar)}</p>

                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-5">
              <img src="images/faq-1-1.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Faqs;
