import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import Footer from "./Footer";
import Swal from 'sweetalert2';

function NewTicket() {
  const { t } = useTranslation();
  const [ticketReq, setTicketReq] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const { name, email, subject, message } = ticketReq;
  const Photo = useRef(null);
  const [userr, setUserr] = useState("");

  useEffect(() => {
    const userId = localStorage.getItem("id");
    setUserr(userId);
  }, []);

  const onInputChange = (e) => {
    setTicketReq({ ...ticketReq, [e.target.name]: e.target.value });
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      let formData = new FormData();
      formData.append("user_id", userr);
      formData.append("name", ticketReq.name);
      formData.append("email", ticketReq.email);
      formData.append("subject", ticketReq.subject);
      formData.append("message", ticketReq.message);
      if(Photo.current.files[0]){
       formData.append("image", Photo.current.files[0]);
      }

      const response = await axios.post(
        "https://changermoney.com/exchange/dashboard/tickets/readAll.php" , {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
              "Content-Type": "application/json" 
                    },
        formData
    });

      if (response.data) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Thanks, we will respond to you.',
        }).then(() => {
          // Optionally redirect or do something else after success
          // console.log(response);
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to submit the ticket. Please try again later.',
        });
      }
    } catch (error) {
      console.error("Error during submission:", error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Failed to submit the ticket. Please try again later.',
      });
    }
  };

  return (
    <>
      <section className="main-banner" id="top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-md-9">
              <div className="singles-contacts-box">

                <form onSubmit={submit} id="second-form">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="section-heading">
                        <h2><em>{t("Reserve")}</em> {t("YourTicket")}</h2>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form_box">
                        <input
                          placeholder={t("YourName")}
                          type="text"
                          name="name"
                          value={ticketReq.name}
                          onChange={onInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form_box">
                        <input
                          placeholder={t("EMailAddress")}
                          type="text"
                          name="email"
                          value={ticketReq.email}
                          onChange={onInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form_box">
                        <input
                          placeholder={t("WhatsAppOr")}
                          type="text"
                          name="message"
                          value={ticketReq.phone}
                          onChange={onInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form_box">
                        <input
                          placeholder={t("Subject")}
                          type="text"
                          name="subject"
                          value={ticketReq.subject}
                          onChange={onInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form_box">
                        <input
                          placeholder={t("YourMessage")}
                          type="text"
                          name="message"
                          value={ticketReq.message}
                          onChange={onInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
           
                    <div className="col-lg-12 col-md-12">
                      <div className="form_box">
                        <p>{t("Proofpayment")}</p>
                        <input
                          type="file"
                          name="Photo"
                          ref={Photo}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-button col-md-6 m-auto">
                      <button className="signinbtn" type="submit">
                        {t("Send")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default NewTicket;
