import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import Swal from "sweetalert2";

const GenerateInvitationLink = () => {
  const [invitationLink, setInvitationLink] = useState("");
  const [userId, setUserId] = useState(localStorage.getItem("id"));
  const { t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log("Error loading language:", err);
      // console.log("Language changed to:", language);
    });
  };
  // Function to generate a random alphanumeric code
  const generateRandomCode = (length) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };
  useEffect(() => {
    var user_id = localStorage.getItem("id");
    if (!user_id) {
      window.location.href = '/';
    }

  }, []);

  const generateLink = async () => {
    const invitationCode = generateRandomCode(8); // Generate an 8-character code
    const username = localStorage.getItem("name");
    setInvitationLink(`https://www.changermoney.com/Register?u_id=${userId}&username=${username}`);
  };

  return (
    <section className="main-banner" id="top">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9 col-md-9">
            <div className="singles-contacts-box">
              <div id="second-form" className="invitioncard py-5 text-center">

                <button onClick={generateLink} className="w-50 my-5 m-auto">{t("Generate")} {t("invitionlink")}</button>

                {invitationLink && (
                  <>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="section-heading">
                          <h2><em>{t("Generate")}</em> {t("invitionlink")} </h2>
                        </div>
                      </div>
                    </div>

                    <div className="row rowsmall">

                      <p> <a href={invitationLink} target="_blank" rel="noopener noreferrer">{invitationLink}</a></p>
                      <button onClick={() => {
                        navigator.clipboard.writeText(invitationLink).then(Swal.fire({
                          icon: 'success',
                          title: 'Link is Copied Successfully',
                          showConfirmButton: false,
                          timer: 1500
                        }))
                      }} className="w-75 m-auto">{t("CopyLink")}</button>
                    </div>
                  </>
                )}
              </div>

            </div>
          </div>
        </div>

      </div>

    </section>

  );
};

export default GenerateInvitationLink;
