import React from "react";
import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "./Footer";

function Thanks() {
    const location = useLocation();
    const { transactionId, firstMethodName, secondMethodName, amount, receivedAmount } = location.state || {};

    return (
        <>
            <section className="feature-area thanks">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="8" md="8">
                            <div className="single-feature-box py-5">
                                <div>
                                <iframe src="https://giphy.com/embed/tf9jjMcO77YzV4YPwE"  width="100%" height="100%" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
                                </div>
                                <div className="feature-title">
                                    <h3>Request Sent</h3>
                                    <p>We will respond to you in 24 hours</p>
                                    {transactionId && (
                                        <div>
                                            <p>Transaction ID: {transactionId}</p>
                                            <p>Send From: {firstMethodName}</p>
                                            <p>Get To: {secondMethodName}</p>
                                            <p>Amount Send: {amount}</p>
                                            <p>Received Amount: {receivedAmount}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default Thanks;
