import { initializeApp } from "@firebase/app"; // use "firebase/app" instead of "@firebase/app"
import { getAuth, FacebookAuthProvider, GoogleAuthProvider } from "@firebase/auth"; // use "firebase/auth" instead of "@firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyBl7EKyCtCMVJwxWC9Z_DPDB24oyDxcg2M",
    authDomain: "exchange-39123.firebaseapp.com",
    projectId: "exchange-39123",
    storageBucket: "exchange-39123.appspot.com",
    messagingSenderId: "818854260702",
    appId: "1:818854260702:web:dbb48423dd9c230ddcf74f",
    measurementId: "G-T1JXMT2JYC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const facebookProvider = new FacebookAuthProvider();
export const googleProvider = new GoogleAuthProvider();
