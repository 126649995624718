import React, { useEffect } from "react";
import Home from "./Home";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import NavBar from "./NavBar";
import ContactUs from "./ContactUs";
import AboutUs from "./AboutUs";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./App.css";
import Login from "./Login.js";
import Register from "./Register.js";
import { useTranslation } from "react-i18next";
import i18n from "./i18n.js";
import ScrollToTop from "./ScrollToTop.js";
import Faqs from "./Faqs.js";
import Services from "./Services.js";
import Exchange from "./Exchange.js";
import Profile from "./Transactions/Profile.js";
import Thanks from "./Thanks.js";
import GenerateInvitationLink from "./GenerateInvitationLink.js";
import Blogs from "./Blogs.js";
import Blogdetail from "./BlogDetail.js";
import Bonusdetail from "./BonusDetail.js";
import Bonus from "./Bonus.js";
import BonusRequest from "./BonusRequest.js";
import Dashboard from "./Dashboard.js";
import NewTicket from "./NewTicket.js";
import Tickets from "./Tickets.js";
import Afflication from "./Afflication.js";
import ProcessingTransactions from "./Transactions/ProcessingTransactions.js";
import CompletedTransactions from "./Transactions/CompletedTransactions.js";
import CanceledTransactions from "./Transactions/CanceledTransactions.js";
import RefundedTransactions from "./Transactions/RefundedTransactions.js";
import PendingExchange from "./Transactions/PendingExchange.js";
import Withdraw from "./Withdraw.js";
import AllWithdraw from "./Transactions/AllWithdraw.js";
import ChangePointsToDollars from "./ChangePointsToDollars.js";
import ResetPassword from "./ResetPassword.js";
import UpdateUserData from "./TheProfile.js";
import ChangePassword from "./ChangePassword.js";
import ForgotPassword from "./ForgetPassword.js";
import ExchangeSecond from "./ExchangeSecond.js";
import Levels from "./Levels.js";
import LevelReq from "./LevelReq.js";
import ApprovedExchanges from "./Transactions/ApprovedExchanges.js";
import ReedmedTransactions from "./Transactions/ReedmedTransactions.js";
import WaitingApprovedExchanges from "./Transactions/WaitingApprovedExchanges.js";
import RefusedTransactions from "./Transactions/RefusedTransactions.js";
import TicketDetail from "./TicketDetail.js";
import TermsConditions from "./TermsConditions.js";
import Privacy from "./Privacy.js";



function App() {
  const { t } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log("Error loading language:", err);
      // console.log("Language changed to:", language);
      localStorage.setItem('language', language);
    });
  };

  // Load language preference from local storage on mount
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en';
    changeLanguage(savedLanguage);
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Root />}>
          <Route index element={<Home />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/Faqs" element={<Faqs />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/exchange" element={<Exchange />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/Thanks" element={<Thanks />} />
          <Route path="/Signin" element={<Login />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Blogs" element={<Blogs />} />
          <Route path="/Blogs/:id" element={<Blogdetail />} />
          <Route path="/Bonus" element={<Bonus />} />
          <Route path="/Bonus/:id" element={<Bonusdetail />} />
          <Route path="/BonusRequest/:id" element={<BonusRequest />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/NewTicket" element={<NewTicket />} />
          <Route path="/Tickets" element={<Tickets />} />
          <Route path="/Afflication" element={<Afflication />} />
          <Route path="/PendingTransactions" element={<PendingExchange />} />
          <Route path="/ProcessingTransactions" element={<ProcessingTransactions />} />
          <Route path="/CompletedTransactions" element={<CompletedTransactions />} />
          <Route path="/ApprovedExchanges" element={<ApprovedExchanges />} />
          <Route path="/WaitingApprovedExchanges" element={<WaitingApprovedExchanges />} />
          <Route path="/RefusedTransactions" element={<RefusedTransactions />} />

          
          
          <Route path="/CanceledTransactions" element={<CanceledTransactions />} />
          <Route path="/RefundedTransactions" element={<RefundedTransactions />} />
          <Route path="/ReedmedTransactions" element={<ReedmedTransactions />} />

          <Route path="/Withdraw" element={<Withdraw />} />
          <Route path="/AllWithdraw" element={<AllWithdraw />} />
          <Route path="/TheProfile" element={<UpdateUserData />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/CompleteExchange" element={<ExchangeSecond />} />
          <Route path="/Levels" element={<Levels />} />
          <Route path="/LevelsUpgrade/:id" element={<LevelReq />} />
          <Route path="/TicketResponse/:id" element={<TicketDetail />} />

          <Route path="/ChangePointsToDollars" element={<ChangePointsToDollars />} />

          <Route path="/forgot-password" element={<ForgotPassword />} /> {/* Add this line */}
        <Route path="/reset-password" element={<ResetPassword />} /> {/* Add this line */}
          {/* <Route path="/Bonus" element={<Bonus />} /> */}
          {/* <Route path="/ResetPassword" element={<ResetPassword />} /> */}

          
          <Route path="/GenerateInvitationLink" element={<GenerateInvitationLink />} />

          <Route path="/TermsConditions" element={<TermsConditions />} />
          <Route path="/Privacy" element={<Privacy />} />


          
        </Route>
      </>
    )
  );

  return (
    <div className={`${t("dir")} app`}>
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;

const Root = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return localStorage.getItem('isDarkMode') === 'true';
  });
  const toggleTheme = () => {
    setIsDarkMode(prevMode => {
      const newMode = !prevMode;
      localStorage.setItem('isDarkMode', newMode);
      return newMode;
    });
  };

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);
  return (
    <div className={`App ${isDarkMode ? 'light-mode' : 'dark-mode'}`}>
      <ScrollToTop />

      <NavBar isDarkMode={isDarkMode} toggleTheme={toggleTheme}/>
      <Outlet />
    </div>
  );
};
